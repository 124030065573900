import { useState, useEffect } from "react";
import Popup from 'reactjs-popup'


function Payslip24({payslip24, setPayslip24, showPayslip24, flag, setSubmittedData}){

    //preview image before submission
    const [preview, setPreview] = useState()

    //display placeholder image for pdf
    const [isPdf, setIsPdf] = useState(false);
    //Error on incorrect file format
    const [wrongFile, setwrongFile]  = useState(false);

    // create a preview as a side effect, whenever selected file is changed
    useEffect(() => {
        if (!payslip24) {
            setPreview(undefined)
        }else{
            if(payslip24.type !== 'application/pdf'){
                // free memory when ever this component is unmounted
                const objectUrl = URL.createObjectURL(payslip24)
                setPreview(objectUrl)
                return () => URL.revokeObjectURL(objectUrl)   
            }else{
                //display icon for pdf here
            }
        
        }

        
    }, [payslip24])

    // encoding document to base64
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleChange = async(e) => {
        let file = e.target.files[0];

        if(file.type === 'application/pdf'){  
            URL.revokeObjectURL(payslip24);
            setPayslip24(file);  
            setIsPdf(true);
        }else{
            setPayslip24(file);
            setIsPdf(false);
        }  
        if(file.type === 'application/pdf' || file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp' ){
            setwrongFile(false);
            const encoded = await toBase64(file)
            setSubmittedData(submittedData => ({...submittedData, payslipOrP602024: encoded.split(",")[1]}))
            setSubmittedData(submittedData => ({...submittedData, payslipOrP602024FileName: `payslipOrP602024.${file.type.split("/")[1]}`}))
        }else{
            setwrongFile(true);
            setPayslip24(null);
        }
    }

    const ProvideId = () =>{
        if(showPayslip24 && payslip24 === null && flag){
            return <p className="danger">In order to proceed, you must provide a payslip, p60 or Pension Statement</p>
        }else{
            return <div></div>
        }
    }

	return(<>
        <div className="col-md-10 w-100 mx-auto">
            <div className='row g-0 d-flex justify-content-between align-items-center mb-5'>
                <div className='col-sm-6 pe-sm-3 text-md-start'>
                    <h6 style={{display: 'inline-block'}}>Please take a photo, or scan your document, and upload your file.</h6>
                    
                </div>
                <div className="col-sm-6">
                    <div className='row g-0 pt-2 mb-1'>
                        <div className="col-12"  style={{overflowWrap: 'anywhere'}}>
                            {preview ? (<img width="scale" height="150" src={preview} alt="preview" className="mb-3 mx-auto d-block"/>) : (<div></div>)}
                            {isPdf ? (<img width="scale" height="150" src="/blank-page.png" alt="preview" className="mb-3 mx-auto d-block"/>):(<div></div>)}
                            {payslip24 ?(<div className="col-12 text-center"><p>{payslip24.name}</p></div>): <div></div>}
                            {wrongFile? (<h3>please only upload the following file types: pdf, jpeg, jpg, png, webp</h3>):(<div></div>)}
                        </div>
                           <Popup modal nested trigger={<button>Upload File</button>}>
                                {close =>( 
                                        <div className='pop-up'>
                                            <div className='d-flex justify-content-center p-4'>
                                                <button className="closeinfo" onClick={close}>
                                                X
                                                </button>
                                                <div className='content'>
                                                    <span>
                                                    You can upload either a picture from your Smartphone or a pdf document of the requested document.
                                                    Your Payslips &amp; P60 should be available from your Employer, or Pension provider if you are retired.
                                                    You can also access your p60 via the HMRC website. Visit the Government Gateway website 
                                                    at <a href='https://www.gov.uk/paye-forms-p45-p60-p11d/p60' rel='norefferer' alt=''>https://www.gov.uk/paye-forms-p45-p60-p11d/p60</a> for
                                                     further information.  If you are claiming state pension, you can get a proof of benefit letter
                                                      from <a href='https://www.gov.uk/paye-forms-p45-p60-p11d/p60' rel='norefferer' alt=''>
                                                        https://secure.dwp.gov.uk/get-a-proof-of-benefit-letter/</a>
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2 px-5 mb-1 text-center">    
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    <label htmlFor="file-upload" className="button"> Upload file</label>
                                                    <input id="file-upload" style={{display: "none"}} type="file" name="file" accept=".pdf, .png, .jpg, .jpeg, .webp;" onChange={(e) => { handleChange(e); close();}}/>
                                                    <label id="mobile-photo" className="button" htmlFor="photo-upload">Take Photo</label>
                                                    <input id="photo-upload" style={{display: "none"}} type="file" name="file" accept=".png, .jpg, .jpeg, .webp" capture="camera" onChange={(e) => { handleChange(e); close();}}/>
                                                </div>
                                            </div>
                                        </div>)}
                                </Popup>
                        <ProvideId />
                    </div>
                </div>
            </div>
        </div>

    </>)
}

export default Payslip24;
