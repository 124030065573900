import React from 'react'
import './App.css'

function Success(props) {

  const { urlParams } = props;

  const trackParams = Object.entries(urlParams)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => {
      switch (key) {
        case 'firstname':
          return `f=${encodeURIComponent(value)}`;
        case 'lastname':
          return `l=${encodeURIComponent(value)}`;
        case 'clientid':
          return `p=${encodeURIComponent(value)}`;
        case 'brand':
          return `b=${encodeURIComponent('CAS')}`;
          case 'marketingChannel':
            return `Mch=${encodeURIComponent('SMS')}`;
          case 'marketingCampaign':
            return `Mca=${encodeURIComponent('Uni2.2a')}`;
          case 'marketingAudience':
            return `Mau=${encodeURIComponent('CAS')}`;
          case 'marketingCreative':
            return `Mcr=${encodeURIComponent('CS0124')}`;
        default:
          return '';
      }
    })
    .join('&');


  return (
<>
           <div className='d-flex flex-grow-1 text-center justify-content-center align-items-center'>
             <div className='row thank-you mb-5'>
               <h1>Submission Successful!</h1>
               <div className='d-flex justify-content-center'>
               <i className='fa-regular fa-circle-check fa-10x'/>
               </div>
               <h2>Thank you for submitting your response</h2>
               <p> This information is vital in helping progress your claim. 
                  {/*We may still need more information, 
                  please <a href={`https://an.myclaim.online/?${trackParams}`} >
                    click here</a>, We will be in touch soon. */} 
                </p>
             </div>
           </div>
         </>
  )
}

export default Success;