import React from 'react'

export default function Intro(props) {
    
    //url Params
    const firstname = props.urlParams["firstname"]
    const lastname = props.urlParams["lastname"]

    if(firstname && lastname !== null){
        return (<>
        <h1 className="mb-4 text-center">Hello {firstname} {lastname},</h1>
        <h4 className='py-sm-5 py-3'>Thank you for previously submitting your marriage tax claim.
Your claim is being processed however, HMRC need some further details to progress. 
Please provide the missing information as detailed below:
</h4>
        </>)
    }else{
        return <div>      <h4 className='py-sm-5 py-3'>Thank you for previously submitting your marriage tax claim.
        Your claim is being processed however, HMRC need some further details to progress. 
        Please provide the missing information as detailed below:
        </h4></div>
    }
}
