import './Header.css'
import  companyLogo2  from './logo/Claims-Advisory-Services.png'


function Header(props) {

    const defendant = props.urlParams["defendant"]
    const brand = props.urlParams["brand"]

    // Dynamic logo and theme based on URL Parameter
    function CompanyLogo (){
        document.documentElement.style.setProperty('--button-color', '#db3936')
        document.documentElement.style.setProperty('--font-color', '--alt-font-color')
        document.documentElement.style.setProperty('--footer-color', '#200303')
        return <img src={companyLogo2} alt="Company logo" />
}

    // dynamic phone number based on URL Paramters
   function PhoneNumber () {    
        
        if (brand === "CAS"){
            return <a href='tel:01902214201'><i className="fa fa-phone"></i></a>
        } else {
            return <><a href='tel:03308224213'><i className="fa fa-phone"></i></a>
            <a className="mobile" href="tel:03308224213">0330 822 4213</a></>
        }
    }

    return (
        <div className='top-bar'>
            <div className="row d-flex">
                <div className='col-8'>
                    <CompanyLogo />
                    {defendant === 'barclays' && <img src='SSB-Law-Logo-no-writing.png' alt='SSB Logo'/>}
                </div>
                <div className='col-4 phone d-flex justify-content-end align-items-center'>
                   <PhoneNumber />
                </div>
            </div>
        </div>
        
    )
}

export default Header;