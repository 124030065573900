import React from 'react'
import Popup from 'reactjs-popup'

export default function ClientID({clientid, shortcode, flag, setShortcode, setSubmittedData}) {


    const ValidShortcode = () => {
        if(clientid === null && shortcode.length === 0 && flag){
            return <p className="danger">Please enter your unique customer reference number to proceed</p>
        }else{
            return <div></div>
        }
    }

  return (<>
<div className="col-md-12">
        <div className='row g-0 d-flex justify-content-center align-items-center mb-5'>
            <div className='col-md-6 px-2 text-md-start'>
                <label htmlFor="shortcode"><h6>Enter your unique customer reference number        
                    <Popup modal nested trigger={ <i className='fa fa-circle-info'
                        style={{color: '#db3936',
                                paddingLeft: '5px'}}></i>}>
                {close =>( 
                        <div className='pop-up'>
                            <div className='d-flex justify-content-center p-4'>
                                <button className="closeinfo" onClick={close}>
                                X
                                </button>
                                <div className='content'>
                                    <span>
                                    
                                    You can find this in the letter we have sent you. Please ensure to enter uppercase
                                        and lowercase letters correctly.
                                    </span>
                                </div>
                            </div>
                        </div>)}
                </Popup><br/></h6></label>                           
            
            </div>
            <div className='col-md-6'>
                <div className='row g-0'>
                    <input 
                        type="text" 
                        id="shortcode" 
                        name="Shortcode"
                        onChange={(e) => {
                            setShortcode(e.target.value)
                            setSubmittedData(submittedData => ({...submittedData, clientid: e.target.value}))
                        }} 
                        value={shortcode}
                    /> 
                    <ValidShortcode />
                </div> 
            </div>
        </div> 
    </div></>

  )
}
