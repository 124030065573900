import React from 'react'

function Footer({showCheckbox}) {


    function CopyRight(){
            return<>
            <div className='d-flex justify-content-center'>
                <div className="footer-width">
                    <p>Claims Advisory Services is a trading style of Legal Ventures Ltd.</p>
                    <br />
                    <p>Swinford House, Albion St, Brierley Hill DY5 3EE and is registered in England and Wales
                     (Company No 13100030 ) VAT registration number 431840906. ICO Reg No. ZA886718.</p>
                </div>
            </div>
        </>
    }
    
      function Brands(){
            return<>
            <div className='d-flex justify-content-center'>
            <div className='details row g-0 col-lg-12'>
                <div className='col-sm-6 col-md-4 mb-5 p-3 cas-footer'>
                    <CopyRight />
                </div>
                <div className='col-sm-6 col-md-4 mb-5 cas-footer p-3'>
                    <h6>CONTACT US</h6><br />
                    <p><a href='tel:01902955253'><i className="fa fa-phone pe-2"></i>01902 955253**</a><br/>
                    <a title="Subject" href="mailto:contactus@claimsadvisoryservices.co.uk"><i className="fa fa-envelope pe-2"></i>contactus@claimsadvisoryservices.co.uk</a><br/>
                    <i className="fa fa-map-marker pe-2"></i>84 Salop Street, Wolverhampton WV3 0SR</p>
                    <br />
                    <p>**Standard rates apply.</p>
                </div>
                <div className='col-sm-6 col-md-4 mb-5 cas-footer p-3'>
                    <h6>LINKS</h6><br />
                    <ul>
                        <li ><a href="https://claimsadvisoryservices.co.uk/faq/">FAQ</a></li>
                        <li ><a href="https://claimsadvisoryservices.co.uk/terms-and-conditions/">TERMS AND CONDITIONS</a></li>
                        <li ><a href="https://claimsadvisoryservices.co.uk/privacy-policy/">PRIVACY POLICY</a></li>
                        <li ><a href="https://claimsadvisoryservices.co.uk/cookie-policy/">COOKIE POLICY</a></li>
                        <li><a href="https://claimsadvisoryservices.co.uk/complaints-procedure/">COMPLAINTS PROCEDURE</a></li>
                    </ul>
                </div>

                <div className='col-12 d-flex justify-content-center cas-footer'><hr/></div>
                <div className='col-12 text-center cas-footer'><p>Copyright 2023 — Claims Advisory Services. All rights reserved.</p></div>
            </div>
        </div>
        </>
      }

  return (<>
   {showCheckbox && <div className='d-flex justify-content-center px-3'>
        <div className='col-md-8 text-center'>
            <p className='terms-text'>Security and privacy of our customers' data is of utmost importance. We adhere to data protection regulations, GDPR and The Privacy & Electronic Communications Regulations, and have established strong safeguards to protect your data.</p>
        </div>
    </div>}
    <div className='footer'>
        <Brands />
    </div>
 </> )
}

export default Footer