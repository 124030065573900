import './App.css';
import { useState, useEffect, useRef } from 'react';
import Payslip24 from './Forms/Payslip24';
import Success from './Success';
import Errorpage from './Errorpage'
import Intro from './Forms/Intro';
import axios from 'axios';
import Footer from './Footer';
import ClientID from './Forms/ClientID';


function App(props) {

  //Content controlling states
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(false)
  const [intro, setIntro] = useState(true);
  const [loading, setLoading] = useState(false)
  const [flag, setFlag] = useState(false)
  const [shortcode, setShortcode] = useState('')
  const [payslip24, setPayslip24]= useState(null)      
  const [showPayslip24, setShowPayslip24] = useState(false)
  const [noPayslip, setNoPayslip] = useState(false)
  const [showCheckbox, setShowCheckox] = useState(false)
  const [submittedData, setSubmittedData] = useState({
    noPayslip: false,
  })
  const [marketingParams, setMarketingParams] = useState ({
    marketingChannel: 'SMS',
    marketingCreative: 'Uni2.2a',
    marketingAudience: 'CAS',
    marketingCampaignName: 'CS0124'
})


  //url parameters
  const firstname = props.urlParams["firstname"]
  const lastname = props.urlParams["lastname"]
  const caseid = props.urlParams["caseid"]
  const clientid = props.urlParams["clientid"]
  const agent = props.urlParams["agent"]
  const marketingChannel = props.urlParams["marketingChannel"]
  const marketingAudience = props.urlParams["marketingAudience"]
  const marketingCampaign = props.urlParams["marketingCampaign"]
  const marketingCreative = props.urlParams["marketingCreative"]
   
  //manage parameters
  useEffect(()=>{
      if(clientid !== null){
          setShortcode(clientid)
          setSubmittedData(submittedData => ({...submittedData, clientid: clientid}))
      }
      if(caseid !== null){
          setSubmittedData(submittedData => ({...submittedData, caseid: caseid}))
      }
      if(agent !== null){
          setSubmittedData(submittedData => ({...submittedData, agent: agent}))
      }
      if(marketingChannel !== null){
          setMarketingParams(marketingParams => ({...marketingParams, marketingChannel: marketingChannel}))
      }
      if(marketingAudience !== null){
          setMarketingParams(marketingParams => ({...marketingParams, marketingAudience: marketingAudience}))
      }
      if(marketingCreative !== null){
          setMarketingParams(marketingParams => ({...marketingParams, marketingCreative: marketingCreative}))
      }
      if(marketingCampaign !== null){
          setMarketingParams(marketingParams => ({...marketingParams, marketingCampaignName: marketingCampaign}))
      }
   },[caseid, shortcode, clientid, agent, marketingCampaign, marketingCreative, marketingAudience, marketingChannel])

  //Scroll to view
  const myRef = useRef(null);
  const scroll = () => {
      myRef.current?.scrollIntoView({behavior: 'smooth', block: 'end' });
  };

  //Handle input toggling
  const handleYes = () =>{
    setShowPayslip24(true)
    setShowCheckox(false)
  }

  const handleNo = () => {
    setShowPayslip24(false)
    setShowCheckox(true)
  }
  const handleCheckbox = () =>{
    setNoPayslip(!noPayslip)
    setSubmittedData({
      ...submittedData,
      noPayslip: !submittedData.noPayslip,
    });
  }

  //Api submission
  const submit = async() =>{
   if(( showPayslip24 && !payslip24) || ( showCheckbox && noPayslip === false) || !submittedData.clientid ){
    setFlag(true)
    setLoading(false)
   }else{
    await axios.post('/api', {
        submittedData,
        marketingParams
      }).then(response =>{   
        if(response.status >=400){
            setIntro(false)
            setError(true)
            setSubmitted(false)
            setLoading(false)
        }else{
            setIntro(false)
            setError(false)
            setSubmitted(true)
            setFlag(false)
            setLoading(false)
        }
    })
    .catch(function (error) {
        console.log(error);
        setIntro(false)
        setError(true)
        setSubmitted(false)
        setLoading(false)
    }); }
  }
 
  return (<>
      {loading &&<div className='col-12 spinning-wheel-background'>
            <div className='col-12 spinning-wheel'></div>
            </div>}
    <div className='intro container'>
        <div className='col-12 d-flex text-center justify-content-center  align-items-between my-5'>
            <div className='row d-flex text-center justify-content-center align-items-center w-100 mx-auto mt-5 sigpreview'>
              {submitted && <Success urlParams={props.urlParams} /> }
              {error && <Errorpage/> }
               
              { intro && <>
                <div className="row g-0 d-flex text-center justify-content-center">
                  <div className='col-md-8 d-flex text-center justify-content-center'>
                    <div className="row g-0 d-flex justify-content-center bank-details w-100">
                      <Intro urlParams={props.urlParams}/>
                      { !clientid && <ClientID clientid={clientid} flag={flag} shortcode={shortcode} setShortcode={setShortcode} 
                      setSubmittedData={setSubmittedData}/> }
                      <div className='col-sm-6 text-start mb-sm-4 p-1'>   
                        <p> Do you have a Payslip, P60 or Pension Statement from the last 4 years?</p>
                      </div>
                      <div className='col-sm-6 yes-no row g-0 mb-sm-4 mb-5'>
                        <div className='col-6 px-2'>
                          <label htmlFor="yes-q1" className='radio yes'>
                              <input 
                                  type="radio"
                                  id="yes-q1" 
                                  name="q1" 
                                  onChange={handleYes}
                              />
                              Yes
                          </label><br/>
                        </div>
                        <div className='col-6 px-2'>
                          <label htmlFor="no-q1" className='radio no'>
                              <input 
                                  type="radio" 
                                  id="no-q1" 
                                  name="q1" 
                                  onChange={handleNo}
                              />
                              No
                          </label><br/>
                        </div>       
                      </div>
                      { showPayslip24 && <Payslip24 showPayslip24={showPayslip24} flag={flag} setSubmittedData={setSubmittedData}
                      payslip24={payslip24} setPayslip24={setPayslip24}/>}
                      { showCheckbox && <>
                         <p><input type='checkbox' onChange={handleCheckbox} 
                          /> I confirm that I do not have any of the above documentation.</p>
                        </>}
                      {(( showPayslip24 && payslip24 !== null) || ( showCheckbox && noPayslip)) &&
                      <div className='col-12 mb-5'>
                        <button className='submit' onClick={() =>{setLoading(true); submit(); scroll();}}>Submit</button> 
                      </div>}
                    </div>
                  </div>
                </div>
              </>}
            </div>
          </div>
      </div>
      <Footer showCheckbox={showCheckbox}/>                  
  </>)
}

export default App;