import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Header from './Header/Header';
import reportWebVitals from './reportWebVitals';
import Footer from './Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

const paths = new URLSearchParams(window.location.search);
const urlParams = {
  caseid: paths.get("c"),
  clientid: paths.get("p"),
  firstname: paths.get("f"),
  lastname: paths.get("l"),
  brand: paths.get("b"),
  marketingChannel: paths.get("Mch"),
  marketingCampaign: paths.get("Mca"),
  marketingAudience: paths.get("Mau"),
  marketingCreative: paths.get("Mcr"),
};

// Apply hardcoded values for parameters with null or undefined values
const urlParamsWithDefaults = {
  ...urlParams,
  brand: urlParams.brand || 'CAS',
  marketingChannel: urlParams.marketingChannel || 'SMS',
  marketingCampaign: urlParams.marketingCampaign || 'Uni2.2a',
  marketingAudience: urlParams.marketingAudience || 'CAS',
  marketingCreative: urlParams.marketingCreative || 'CS0124',
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Header urlParams={{'brand':paths.get("b"), 'defendant':paths.get("d")}}/>
      <App urlParams={urlParamsWithDefaults} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
