import React from 'react'
import './App.css'

function Errorpage() {
    

  return (
<>
            <div className='d-flex flex-grow-1 text-center justify-content-center align-items-center'>
              <div className='row thank-you'>
                 <h1>An Error Occured!</h1>
                 <h2>Please Refresh and try again.</h2>
              </div>
            </div>
          </>
  )
}

export default Errorpage